import React from 'react'

import StreamingTileSlotConnector from 'components/StreamingTileSlotConnector'
import { GroupId } from 'types/group'

import { Container, Row } from './StreamingTileGroup.styles'

export interface StreamingTileGroupProps {
  groupId: GroupId
}

const StreamingTileGroup: React.FC<StreamingTileGroupProps> = ({ groupId }) => (
  <Container>
    <Row>
      <StreamingTileSlotConnector groupId={groupId} position={0} />
      <StreamingTileSlotConnector groupId={groupId} position={1} />
      <StreamingTileSlotConnector groupId={groupId} position={2} />
    </Row>
    <Row>
      <StreamingTileSlotConnector groupId={groupId} position={3} />
      <StreamingTileSlotConnector groupId={groupId} position={4} />
      <StreamingTileSlotConnector groupId={groupId} position={5} />
    </Row>
  </Container>
)

export default StreamingTileGroup
