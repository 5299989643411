import tw from 'twin.macro'

export const Container = tw.div`
  flex-1
  flex
  flex-col
  gap-[8px]
`

export const Row = tw.div`
  flex
  gap-[8px]
`
