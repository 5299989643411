import React from 'react'

import Controls from './Controls'
import { Container } from './GroupsNavigator.styles'
import SelectedGroup from './SelectedGroup'

const GroupsNavigator: React.FC = () => (
  <Container>
    <Controls />
    <SelectedGroup />
  </Container>
)

export default GroupsNavigator
