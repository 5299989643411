import React from 'react'
import styled from 'styled-components'
import tw from 'twin.macro'

const RadioButton = styled.button<{ selected?: boolean }>`
  ${tw`text-gray-500 px-1 py-0.5 border-radius[4px]`}
  ${tw`hover:bg-[rgb(55, 55, 55)] hover:text-white`}
  ${({ selected }) => selected && tw`font-semibold text-white`}
`

export interface SetSelectedGroupIdButtonProps {
  groupId: string
  selected: boolean
  onClick: () => void
}

const SetSelectedGroupIdButton: React.FC<SetSelectedGroupIdButtonProps> = ({
  groupId,
  selected,
  onClick,
}) => (
  <RadioButton onClick={onClick} selected={selected}>
    {groupId}
  </RadioButton>
)

export default SetSelectedGroupIdButton
