import React from 'react'
import { useRecoilValue } from 'recoil'

import StreamingTileGroup from 'components/StreamingTileGroup'
import store from 'store'

const SelectedGroup: React.FC = () => {
  const groupId = useRecoilValue(store.streamingTiles.selectedGroupId)

  return <StreamingTileGroup groupId={groupId} />
}

export default SelectedGroup
