import tw from 'twin.macro'

export const Container = tw.div`
  flex
  flex-col
`

export const ControlsContainer = tw.div`
  flex
  gap-0.5
  py-0.5
`
