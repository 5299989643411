import React from 'react'
import { useRecoilState } from 'recoil'

import store from 'store'

import { ControlsContainer } from './GroupsNavigator.styles'
import SetSelectedGroupIdButton from './SetSelectedGroupIdButton'

const groupIds = ['Alfa', 'Beta', 'Gamma', 'Delta']

const Controls: React.FC = () => {
  const [selectedGroupId, setSelectedGroupId] = useRecoilState(
    store.streamingTiles.selectedGroupId,
  )

  return (
    <ControlsContainer>
      {groupIds.map((groupId) => (
        <SetSelectedGroupIdButton
          key={groupId}
          groupId={groupId}
          selected={selectedGroupId === groupId}
          onClick={() => setSelectedGroupId(groupId)}
        />
      ))}
    </ControlsContainer>
  )
}

export default Controls
