import React from 'react'

import ClientSideToplevel from 'components/ClientSideToplevel'
import GroupsNavigator from 'components/GroupsNavigator'

const IndexPage: React.FC = () => {
  return (
    <ClientSideToplevel>
      <GroupsNavigator />
    </ClientSideToplevel>
  )
}

export default IndexPage
